// import * as _ from 'lodash';
import axios, {
  InternalAxiosRequestConfig,
	type AxiosError,
	type AxiosRequestConfig,
	type AxiosResponse,
} from "axios";

import { toast } from "react-toastify";

import { useEventCallback } from "@jvs-group/jvs-mairistem-tools";

// import * as host from './host';;

import Sentry from "../services/sentry";

import type { Module } from "../entities/Module";
import { type User, UserService } from "../entities/User";

const logout = async (user: User) => {
	toast.info("Votre session a expiré", { toastId: "INF_SESSION_EXPIRED" });

	await UserService.logout(user);

	return Promise.reject({
		code: 401,
		type: "Unauthorized",
		message: "Votre session a expiré",
	});
};

const refreshToken = (user: User, config: AxiosRequestConfig) => {
	return UserService.refresh(user)
		.then((bearer) => {
			if (bearer) {
				return axios.get(config.url, {
					...config,
					headers: {
						...config.headers,
						Authorization: `Bearer ${bearer}`,
					},
				});
			}

			return logout(user);
		})
		.catch(() => {
			return logout(user);
		});
};

export const useAxios = (user: User, module?: Module) => {
	const requestData = useEventCallback((config: InternalAxiosRequestConfig) => {
		/* eslint-disable no-param-reassign */
		config.headers.Authorization = `Bearer ${user?.token?.access}`;
		config.headers["X-User"] = user?.identifiant;
		config.headers["X-Tenant"] = user?.tenant.identifiant;
		config.headers["X-Component"] = user?.component.identifiant;
		config.headers["X-Produit"] = module?.identifiant;
		config.headers["X-Domaine"] = module?.serial?.domaine;
		config.headers["X-Produit-Domaine"] = module?.serial?.produit;
		/* eslint-enable no-param-reassign */

		return config;
	});

	const requestError = useEventCallback((error: Error) =>
		Promise.reject(error),
	);

	const responseData = useEventCallback((data: AxiosResponse) => data);

	const responseError = useEventCallback(
		(error: AxiosError<{ message: string }>) => {
			const { response } = error;

			if (response?.status) {
				if (response.status > 499) {
					Sentry?.captureException(error);
				}

				if (response.status === 401) {
					return refreshToken(user, response.config);
				}

				return {
					code: response.status,
					type: response.statusText,
					message: response.data?.message || response.data,
				};
			}

			return error;
		},
	);

	return {
		request: { data: requestData, error: requestError },
		response: { data: responseData, error: responseError },
	};
};
